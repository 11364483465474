exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-technology-marketmake-js": () => import("./../../../src/pages/technology/marketmake.js" /* webpackChunkName: "component---src-pages-technology-marketmake-js" */),
  "component---src-pages-technology-stake-js": () => import("./../../../src/pages/technology/stake.js" /* webpackChunkName: "component---src-pages-technology-stake-js" */),
  "component---src-pages-technology-swap-js": () => import("./../../../src/pages/technology/swap.js" /* webpackChunkName: "component---src-pages-technology-swap-js" */),
  "component---src-pages-technology-transact-js": () => import("./../../../src/pages/technology/transact.js" /* webpackChunkName: "component---src-pages-technology-transact-js" */)
}

